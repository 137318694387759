// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-section-js": () => import("./../src/templates/about-section.js" /* webpackChunkName: "component---src-templates-about-section-js" */),
  "component---src-templates-blog-js": () => import("./../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-contact-section-js": () => import("./../src/templates/contact-section.js" /* webpackChunkName: "component---src-templates-contact-section-js" */),
  "component---src-templates-home-section-js": () => import("./../src/templates/home-section.js" /* webpackChunkName: "component---src-templates-home-section-js" */),
  "component---src-templates-project-post-js": () => import("./../src/templates/project-post.js" /* webpackChunkName: "component---src-templates-project-post-js" */),
  "component---src-templates-projects-js": () => import("./../src/templates/projects.js" /* webpackChunkName: "component---src-templates-projects-js" */),
  "component---src-templates-projects-section-js": () => import("./../src/templates/projects-section.js" /* webpackChunkName: "component---src-templates-projects-section-js" */)
}

